import React from "react";
import TableHeader from "../tableheader";
import TableRow from "../tablerow";

class Schedule extends React.Component {

  render() {
    return (
      <table className="table">
        <thead>
          <TableHeader
            day="Day"
            event="Event"
            notes="Notes"
            time="Time"
            where="Location"
          />
        </thead>
        <tbody>

          <TableRow
            color="purple"
            day="Thursday"
            event="Arrival & Check-in"
            notes="Paperwork and orientation"
            time="2:00 pm - 8:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="purple"
            event="Fellowship"
            notes="Make your own sub"
            time="4:00 pm - 6:00 pm"
            where="Dining Hall"
          />

          <TableRow
            color="purple"
            event="[Message]: &quot;The Truth is Not Relative&quot;"
            notes="Speaker: Ralph Monroe"
            time="6:30 pm - 7:30 pm"
            where="The Chapel"
          />

          <TableRow
            color="purple"
            event="[Message]: &quot;The Three Faceted  Will of God&quot;"
            notes="Speaker: Barney Monroe"
            time="8:00 pm - 9:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="purple"
            event="Fellowship & Music"
            time="9:00 pm - 10:30 pm"
          />

          <TableRow
            color="blue"
            day="Friday"
            event="Dining Room Discussions"
            notes="Coffee, Muffins, and Q&amp;A"
            time="6:30 am - 8:00 am"
            where="Dining Hall"
          />

          <TableRow
            color="blue"
            event="Breakfast"
            time="8:00 am - 9:00 am"
            where="Dining Hall"
          />

          <TableRow
            color="blue"
            event="[Message]: &quot;How Do I Pray?&quot;"
            notes="Speaker: Caleb Bill"
            time="9:30 am - 10:45 am"
            where="The Chapel"
          />

          <TableRow
            color="blue"
            event="Break"
            notes="Stretch legs"
            time="10:45 am - 11:00 am"
            where="The Chapel"
          />

          <TableRow
            color="blue"
            event="[Message]: &quot;Are we to Judge?&quot;"
            notes="Speaker: Gregg Forster"
            time="11:00 am - 12:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="blue"
            event="Lunch"
            time="12:30 pm - 2:00 pm"
            where="Dining Hall"
          />

          <TableRow
            color="blue"
            event="Relationship Seminar"
            time="2:30 pm - 3:30 pm"
            where="The Chapel"
          />

          <TableRow
            color="blue"
            event="Free Time"
            time="2:30 pm - 5:30 pm"
          />

          <TableRow
            color="blue"
            event="[Message]: Women&apos;s Meeting"
            time="4:00 pm - 5:00 pm"
          />

          <TableRow
            color="blue"
            event="Supper"
            time="5:30 pm - 6:30 pm"
          />

          <TableRow
            color="blue"
            event="Message: &apos;Biblical Separation&apos;"
            notes="Speaker: Billy Elmquist"
            time="7:00 pm - 8:30 pm"
            where="The Chapel"
          />

          <TableRow
            color="blue"
            event="Fellowship & Music"
            time="8:30 pm - 10:30 pm"
            where="The Chapel"
          />

          <TableRow
            color="green"
            day="Saturday"
            event="Dining Room Discussions"
            notes="Coffee, Muffins,  and Q&A"
            time="6:30 am - 8:00 am"
            where="Dining Hall"
          />

          <TableRow
            color="green"
            event="Breakfast"
            time="8:00 am - 9:00 am"
            where="Dining Hall"
          />

          <TableRow
            color="green"
            event="[Message]: Timothy Sessions"
            notes="Speakers: Young Men"
            time="9:30 am - 10:45 am"
            where="The Chapel"
          />

          <TableRow
            color="green"
            event="Break"
            notes="Stretch legs"
            time="10:45 am - 11:00 am"
            where="The Chapel"
          />

          <TableRow
            color="green"
            event="[Message]: Timothy Sessions"
            notes="Speakers: Young Men"
            time="11:00 am - 12:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="green"
            event="Lunch"
            notes=""
            time="12:00 pm - 2:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="green"
            event="Dining Hall"
            notes="Free Time"
            time="2:30 pm - 5:30 pm"
            where="The Chapel"
          />

          <TableRow
            color="green"
            event="Camp Picture"
            notes="Outside Chapel"
            time="4:45 pm - 5:15 pm"
            where="The Chapel"
          />

          <TableRow
            color="green"
            event="Supper"
            time="5:30 pm - 6:30 pm"
            where="Dining Hall"
          />

          <TableRow
            color="green"
            event="[Message]: &quot;Prioritizing Money & Things&quot;"
            notes="Speaker: Larry Gabbard"
            time="7:00 pm - 8:30 pm"
            where="The Chapel"
          />

          <TableRow
            color="green"
            event="Fellowship & Music"
            time="8:30 pm - 10:30 pm"
            where="The Chapel"
          />

          <TableRow
            color="red"
            day="Sunday"
            event="Breakfast"
            time="8:00 am - 9:00 am"
            where="Dining Hall"
          />

          <TableRow
            color="red"
            event="[Message]: &apos;Why It Is Reasonable&apos;"
            notes="Speaker: Bill Barnett"
            time="9:30 am - 10:45 am"
            where="The Chapel"
          />

          <TableRow
            color="red"
            event="[Message]: &apos;What Grace Gives Us&apos;"
            notes="Speaker: Barney Monroe"
            time="11:00 am - 12:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="red"
            event="Lunch"
            time="12:00 pm - 1:30 pm"
            where="Dining Hall"
          />

          <TableRow
            color="red"
            event="Clean Up"
            notes="Cabins, The Chapel, and Dining Hall"
            time="1:30 pm - 2:30 pm"
            where="All Buildings"
          />

        </tbody>
      </table>
    );
  }

}

export default Schedule;
